// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ban-ciao-js": () => import("./../../../src/pages/banCiao.js" /* webpackChunkName: "component---src-pages-ban-ciao-js" */),
  "component---src-pages-coach-js": () => import("./../../../src/pages/coach.js" /* webpackChunkName: "component---src-pages-coach-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-group-lesson-js": () => import("./../../../src/pages/groupLesson.js" /* webpackChunkName: "component---src-pages-group-lesson-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-lesson-js": () => import("./../../../src/pages/lesson.js" /* webpackChunkName: "component---src-pages-lesson-js" */),
  "component---src-pages-neihu-js": () => import("./../../../src/pages/neihu.js" /* webpackChunkName: "component---src-pages-neihu-js" */),
  "component---src-pages-news-1-js": () => import("./../../../src/pages/news-1.js" /* webpackChunkName: "component---src-pages-news-1-js" */),
  "component---src-pages-news-10-js": () => import("./../../../src/pages/news-10.js" /* webpackChunkName: "component---src-pages-news-10-js" */),
  "component---src-pages-news-2-js": () => import("./../../../src/pages/news-2.js" /* webpackChunkName: "component---src-pages-news-2-js" */),
  "component---src-pages-news-3-js": () => import("./../../../src/pages/news-3.js" /* webpackChunkName: "component---src-pages-news-3-js" */),
  "component---src-pages-news-4-js": () => import("./../../../src/pages/news-4.js" /* webpackChunkName: "component---src-pages-news-4-js" */),
  "component---src-pages-news-5-js": () => import("./../../../src/pages/news-5.js" /* webpackChunkName: "component---src-pages-news-5-js" */),
  "component---src-pages-news-6-js": () => import("./../../../src/pages/news-6.js" /* webpackChunkName: "component---src-pages-news-6-js" */),
  "component---src-pages-news-7-js": () => import("./../../../src/pages/news-7.js" /* webpackChunkName: "component---src-pages-news-7-js" */),
  "component---src-pages-news-8-js": () => import("./../../../src/pages/news-8.js" /* webpackChunkName: "component---src-pages-news-8-js" */),
  "component---src-pages-news-9-js": () => import("./../../../src/pages/news-9.js" /* webpackChunkName: "component---src-pages-news-9-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-question-js": () => import("./../../../src/pages/question.js" /* webpackChunkName: "component---src-pages-question-js" */),
  "component---src-pages-service-item-js": () => import("./../../../src/pages/serviceItem.js" /* webpackChunkName: "component---src-pages-service-item-js" */),
  "component---src-pages-yonghe-js": () => import("./../../../src/pages/yonghe.js" /* webpackChunkName: "component---src-pages-yonghe-js" */)
}

